import localforage from "localforage";
import { useEffect, useMemo, useState } from "react";
import { formatTs, safe } from "@ckal-software/ckal-lib";
import { LF_LOGS } from "../../lib/lib";
import { useChangeTopColor } from "../../lib/hooks/useChangeTopColor";

interface LogViewerProps {
  onClose(): void;
}

export function LogViewer(props: LogViewerProps) {
  const [logs, setLogs] = useState<{ timestamp: string; message: string }[]>(
    []
  );

  useEffect(() => {
    localforage.getItem(LF_LOGS, (_e, v) => {
      if (Array.isArray(v)) {
        setLogs(v);
      }
    });
  }, []);

  useChangeTopColor("#262c36");

  const mappedLogs = useMemo(
    () =>
      logs.map((l) => {
        const [, obj] = safe(() => JSON.parse(l.message));

        if (obj) {
          return { ...l, message: JSON.stringify(obj, null, 2) };
        }

        return l;
      }),
    [logs]
  );

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "calc(100vh - 36px)",
        width: "calc(100vw - 24px)",
        padding: "0 12px 36px 12px",
        background: "#262c36",
        zIndex: 10000,
        fontSize: 14,
      }}
    >
      <div
        style={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={props.onClose}
          style={{ margin: "4px 0" }}
        >{`<- Back`}</div>
      </div>
      <div
        style={{
          background: "#151b23",
          height: "calc(100% - 24px)",
          borderRadius: 8,
          width: "100%",
          overflow: "auto",
          border: "1px solid #3d444d",
        }}
      >
        <div
          style={{
            padding: 12,
            display: "grid",
            rowGap: 8,
          }}
        >
          {mappedLogs.map((log, i) => (
            <div
              key={i}
              style={{
                fontFamily: "monospace",
                borderRadius: 4,
                padding: 8,
                background: "#262c36",
                color: "white",
                border: "1px solid #3d444d",
              }}
            >
              <div style={{ marginBottom: 8, opacity: 0.7 }}>
                {formatTs(log.timestamp, { type: "LOCAL" })}
              </div>
              <pre style={{ whiteSpace: "break-spaces", margin: 0 }}>
                {log.message}
              </pre>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
