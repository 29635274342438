import { createContext } from "react";
import { Action } from "./lib";
import { ItemWithPopularity, ShoppingListItem } from "./models";
import {
  CategoryOrder,
  Dish,
  Folder,
  Group,
  Item,
  ShoppingList,
  WeekPlan,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { UserInfo } from "@ckal-software/ckal-lib";

export const GroupContext = createContext<{
  group: Group | undefined;
  hasFetchedGroup: boolean;
  fetchMyGroup(): Promise<void>;
}>({ group: undefined, hasFetchedGroup: false, fetchMyGroup: async () => {} });

export const DataContext = createContext<{
  shoppingList: ShoppingList | undefined;
  setShoppingList(shoppingList: ShoppingList): void;
  dishes: Dish[];
  isFetchingDishes: boolean;
  refreshDishes(): Promise<void>;
  weekPlan: WeekPlan | undefined;
  setWeekPlan(weekPlan: WeekPlan): void;
  refreshWeekPlan(): Promise<void>;
  isFetchingWeekPlan: boolean;
  items: ItemWithPopularity[];
  updateItems(items: Item[]): void;
  itemsMap: { [id in string]: ShoppingListItem };
  refreshItems(noLoading?: boolean): Promise<void>;
  isFetchingItems: boolean;
  setIsInputting(isInputting: boolean): void;
  folders: Folder[];
  setFolders(folders: Folder[]): void;
  isFetchingFolders: boolean;
  refreshFolders(): void;
  currentAction: Action | undefined;
  setCurrentAction(currentAction: Action | undefined): void;
  selectedFolder: Folder | undefined;
  setSelectedFolder: (folder: Folder | undefined) => void;
  categoryOrders: CategoryOrder[];
  selectedCategoryOrderId: string;
  updateSelectedCategoryOrderId: (categoryOrderId: string) => void;
  setCategoryOrders: React.Dispatch<React.SetStateAction<CategoryOrder[]>>;
  refreshCategoryOrders: () => void;
}>({
  shoppingList: undefined,
  setShoppingList: () => {},
  dishes: [],
  isFetchingDishes: false,
  refreshDishes: async () => {},
  weekPlan: undefined,
  setWeekPlan: () => {},
  refreshWeekPlan: async () => {},
  isFetchingWeekPlan: false,
  items: [],
  updateItems: () => {},
  itemsMap: {},
  refreshItems: async () => {},
  isFetchingItems: false,
  setIsInputting: () => {},
  folders: [],
  setFolders: () => {},
  isFetchingFolders: false,
  refreshFolders: () => {},
  currentAction: undefined,
  setCurrentAction: () => {},
  selectedFolder: undefined,
  setSelectedFolder: () => {},
  categoryOrders: [],
  selectedCategoryOrderId: "",
  updateSelectedCategoryOrderId: () => {},
  setCategoryOrders: () => {},
  refreshCategoryOrders: () => {},
});

export const UserInfoContext = createContext<UserInfo>({
  email: "",
  firstname: "",
  lastname: "",
  fullName: "",
  nickname: "",
});

export const AllUsersContext = createContext<UserInfo[]>([]);
