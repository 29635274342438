import { useContext } from "react";
import {
  CalendarOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { NavigationBarItem } from "./navigationBarItem";
import { useNavigate, useLocation } from "react-router-dom";
import { Badge } from "antd";
import { DataContext } from "../../lib/contexts";

interface NavigationBarProps {
  isInputting: boolean;
}

export function NavigationBar(props: NavigationBarProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const { shoppingList, setCurrentAction } = useContext(DataContext);

  const divider = (
    <div style={{ height: "60%", width: "2px", background: "lightgray" }} />
  );

  return (
    <div
      style={{
        display: props.isInputting ? "none" : undefined,
      }}
      className={"navigation-bar"}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr auto 1fr auto 1fr",
          gridAutoRows: "1fr",
          alignItems: "center",
          height: "100%",
        }}
      >
        <NavigationBarItem
          onClick={() => {
            navigate("/shopping-list");
            setCurrentAction(undefined);
          }}
          icon={
            <Badge count={(shoppingList?.items || []).length}>
              <ShoppingCartOutlined
                className={
                  "navigation-bar__icon" +
                  (location.pathname.includes("/shopping-list")
                    ? " navigation-bar__icon--highlight"
                    : "")
                }
              />
            </Badge>
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => {
            navigate("/week-plan");
            setCurrentAction(undefined);
          }}
          icon={
            <CalendarOutlined
              className={
                "navigation-bar__icon" +
                (location.pathname.includes("/week-plan")
                  ? " navigation-bar__icon--highlight"
                  : "")
              }
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => {
            navigate("/catalogue");
            setCurrentAction(undefined);
          }}
          icon={
            <UnorderedListOutlined
              className={
                "navigation-bar__icon" +
                (location.pathname.includes("/catalogue")
                  ? " navigation-bar__icon--highlight"
                  : "")
              }
            />
          }
        />
        {divider}
        <NavigationBarItem
          onClick={() => {
            navigate("/profile");
            setCurrentAction(undefined);
          }}
          icon={
            <SettingOutlined
              className={
                "navigation-bar__icon" +
                (location.pathname.includes("/profile")
                  ? " navigation-bar__icon--highlight"
                  : "")
              }
            />
          }
        />
      </div>
    </div>
  );
}
