import { getCallInternalEndpointFunction, now } from "@ckal-software/ckal-lib";
import {
  CredentialsHandler,
  CSE_API_KEY,
  CSE_ID,
  LF_LOGS,
  Weekday,
} from "./lib";
import { YearWeekDay } from "./models";
import localforage from "localforage";
import moment from "moment";
import "moment-timezone";

export const callEndpoint = getCallInternalEndpointFunction(
  CredentialsHandler.getAccessToken,
  log
);

export function intToWeekday(int: number): Weekday {
  if (int === 0) {
    return "Mandag";
  } else if (int === 1) {
    return "Tirsdag";
  } else if (int === 2) {
    return "Onsdag";
  } else if (int === 3) {
    return "Torsdag";
  } else if (int === 4) {
    return "Fredag";
  } else if (int === 5) {
    return "Lørdag";
  } else {
    return "Søndag";
  }
}

async function getFirst10ImageUrls(searchQuery: string) {
  try {
    const response = await fetch(
      `https://www.googleapis.com/customsearch/v1?key=${CSE_API_KEY}&cx=${CSE_ID}&q=${searchQuery}`
    );

    if (!response.ok) {
      throw new Error(`${response.status}: ${response.statusText}`);
    }

    const responseAsJson = await response.json();

    if (responseAsJson.items && responseAsJson.items.length > 0) {
      return responseAsJson.items
        .map((item: { pagemap?: { cse_image: { src: string }[] } }) => {
          if (item.pagemap && item.pagemap["cse_image"]) {
            return item.pagemap["cse_image"][0]?.src;
          } else {
            return "";
          }
        })
        .filter((url: string) => !!url);
    }
  } catch (error) {
    log(error as Error);
    return [];
  }
}

export async function getDishImages(dishName: string) {
  const fetches = [
    getFirst10ImageUrls(dishName + " opskrift"),
    getFirst10ImageUrls(dishName + " mad"),
    getFirst10ImageUrls(dishName + " lækkert"),
  ];
  const promises = await Promise.all(fetches);
  return Array.from(
    new Set(promises.reduce((prev, curr) => prev.concat(curr), [])).values()
  ) as string[];
}

export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getYearWeekDayRelative(relativeWeek: number): YearWeekDay {
  const d = new Date();
  d.setUTCDate(d.getUTCDate() + 7 * relativeWeek);
  return getYearWeekDay(d);
}

function getYearWeekDay(date: Date): YearWeekDay {
  const day = date.getDay();

  return {
    year: date.getUTCFullYear(),
    week: getkWeekNumber(date),
    day: day === 0 ? 6 : day - 1,
    timestamp: date.getTime(),
  };
}

function getkWeekNumber(date: Date): number {
  // Ensure that the date is in the Copenhagen time zone
  const denmarkDate = moment(date).tz("Europe/Copenhagen");
  return denmarkDate.isoWeek();
}

export function getDateFromYearWeekDay(yearWeekDay: YearWeekDay, day: number) {
  const newDate = new Date(yearWeekDay.timestamp);
  newDate.setUTCDate(newDate.getUTCDate() + (day - yearWeekDay.day));

  const monthAsText = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ][newDate.getMonth()];

  return newDate.getDate() + ". " + monthAsText;
}

export function doFormalize(s: string) {
  return s
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

export function getStringFromYearWeekDay(
  yearWeekDay: YearWeekDay,
  replaceDay?: number
) {
  return (
    "" +
    yearWeekDay.year +
    yearWeekDay.week.toString().padStart(2, "0") +
    (replaceDay !== undefined ? replaceDay : yearWeekDay.day)
  );
}

export async function forceRefresh() {
  window.location.reload();
}

export function log(error: Error | string) {
  console.log(error);

  localforage.getItem(LF_LOGS, (_e, v) => {
    const newLogs = [
      {
        timestamp: now(),
        message:
          typeof error === "string" ? error : error.stack || error.message,
      },
    ];

    if (Array.isArray(v)) {
      newLogs.push(...v);
    }

    localforage.setItem(LF_LOGS, newLogs.slice(0, 100));
  });
}
