import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginScreen } from "./components/loginScreen";
import { Main } from "./components/main";
import * as localForage from "localforage";
import { WelcomeScreen } from "./components/views/welcomeScreen";
import { GRAY_BACKGROUND } from "./lib/style_definitions";
import { CredentialsHandler, LF_LAST_PATH } from "./lib/lib.js";
import { UserInfo } from "@ckal-software/ckal-lib";
import { useRefreshIfOld } from "./lib/hooks/useRefreshIfOld.js";

export function App() {
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isGettingUserInfo, setIsGettingUserInfo] = useState(false);
  const [isWorking, setIsWorking] = useState(false);
  const [isFirstLoginTry, setIsFirstLoginTry] = useState(true);

  const navigate = useRef(useNavigate());

  useRefreshIfOld();

  const updateUserInfo = useCallback(() => {
    setIsGettingUserInfo(true);
    CredentialsHandler.getUserInfo()
      .then((userInfo) => {
        setUserInfo(userInfo);
        localForage.getItem(LF_LAST_PATH, (_err, val) => {
          if (val) {
            navigate.current(val + "" || "/week-plan");
          }
        });
      })
      .catch(() => {})
      .finally(() => setIsGettingUserInfo(false));
  }, []);

  useEffect(updateUserInfo, [updateUserInfo]);

  return (
    <div
      style={{ height: "100vh", background: GRAY_BACKGROUND, width: "100vw" }}
    >
      {isGettingUserInfo && isFirstLoginTry && !isWorking ? (
        <WelcomeScreen />
      ) : (
        <>
          {!userInfo ? (
            <LoginScreen
              onLogin={() => {
                localForage.setItem(LF_LAST_PATH, "/week-plan");
                updateUserInfo();
              }}
              isWorking={isWorking}
              setIsWorking={setIsWorking}
            />
          ) : (
            <Main
              userInfo={userInfo}
              onLogout={async () => {
                setIsFirstLoginTry(false);
                await CredentialsHandler.clearCredentials();
                setUserInfo(undefined);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
