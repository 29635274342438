import { useEffect } from "react";
import { z, ZodSchema } from "zod";
import localForage from "localforage";
import { log } from "../functions";

export function useCache<T extends ZodSchema>(input: {
  value: z.infer<T> | undefined;
  setValue: (v: z.infer<T> | undefined) => void;
  schema?: T;
  lfKey: string;
}) {
  const { lfKey, schema, setValue, value } = input;

  useEffect(() => {
    localForage.getItem(lfKey, (_, v) => {
      try {
        schema?.parse(v);
        setValue(v);
      } catch (e) {
        log(`Unable to load cache for ${lfKey}`);
        log(e as Error);
      }
    });
  }, [setValue, schema, lfKey]);

  useEffect(() => {
    localForage.setItem(lfKey, value);
  }, [value, lfKey]);
}
