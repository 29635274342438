import { useContext } from "react";
import { DataContext } from "../../lib/contexts";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CategoryOrderSection } from "./CategoryOrder";
import {
  CreateCategoryOrderEndpoint,
  DEFAULT_CATEGORY_ORDER_NAME,
  ItemCategory,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { freshId } from "@ckal-software/ckal-lib";
import { OptimisticalCategoryOrderUpdateAction } from "../../lib/models";
import { useNotifcation } from "../../lib/hooks/useNotification";
import { callEndpoint, log } from "../../lib/functions";

export function CategoryOrdersContent() {
  const { categoryOrders, setCategoryOrders, refreshCategoryOrders } =
    useContext(DataContext);

  const notify = useNotifcation();

  function optimisticallyUpdateCategoryOrders(
    action: OptimisticalCategoryOrderUpdateAction
  ) {
    if (action.type === "create") {
      setCategoryOrders((curr) =>
        curr
          .concat({
            name: `${DEFAULT_CATEGORY_ORDER_NAME} ${curr.length + 1}`,
            _id: freshId(),
            order: Object.values(ItemCategory),
            groupShortId: 0,
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      );
      return;
    }

    if (action.type === "delete") {
      setCategoryOrders((curr) => curr.filter((co) => co._id !== action.id));
      return;
    }

    if (action.type === "name") {
      setCategoryOrders((curr) =>
        curr
          .map((co) =>
            co._id === action.id ? { ...co, name: action.name } : co
          )
          .sort((a, b) => a.name.localeCompare(b.name))
      );
      return;
    }

    if (action.type === "order") {
      const co = categoryOrders.find((co) => co._id === action.id);

      if (!co) {
        log("Could not find category order");
        notify("Der skete en uventet fejl");
        return categoryOrders;
      }

      const indexOfCategory = co.order.indexOf(action.category);
      const otherIndex = indexOfCategory + (action.direction === "up" ? -1 : 1);

      co.order[indexOfCategory] = co.order[otherIndex];
      co.order[otherIndex] = action.category;

      setCategoryOrders(
        categoryOrders.map((c) => (c._id === action.id ? co : c))
      );
    }
  }

  async function createCategoryOrder() {
    optimisticallyUpdateCategoryOrders({ type: "create" });

    const [err] = await callEndpoint(CreateCategoryOrderEndpoint, undefined);

    refreshCategoryOrders();

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 16,
        }}
      >
        <Button
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          onClick={createCategoryOrder}
        >
          Ny rækkefølge
        </Button>
      </div>
      <div style={{ display: "grid", rowGap: 8 }}>
        {categoryOrders.map((categoryOrder) => (
          <CategoryOrderSection
            key={categoryOrder._id}
            categoryOrder={categoryOrder}
            optimisticallyUpdateCategoryOrders={
              optimisticallyUpdateCategoryOrders
            }
          />
        ))}
      </div>
    </div>
  );
}
