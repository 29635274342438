import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { View } from "./view";
import { PlusOutlined, SearchOutlined, SmileOutlined } from "@ant-design/icons";
import { SEARCH_ITEM_LIST_HEIGHT, ItemCategoryWithBought } from "../../lib/lib";
import {
  ItemWithPopularity,
  ShoppingListItemIdAndAmount,
} from "../../lib/models";
import { callEndpoint, doFormalize as doNormalize } from "../../lib/functions";
import { AddItemContent } from "../shopping/addItemContent";
import { ViewMessage } from "../general/viewMessage";
import { DataContext, GroupContext } from "../../lib/contexts";
import { BLUE } from "../../lib/style_definitions";
import { CategorySection } from "../shopping/categorySection";
import { addItemToStats } from "../../lib/statisticsHandler";
import { Spinner } from "../general/spinner";
import { MInput } from "../elements/mInputs";
import { MModal } from "../elements/mmodal";
import { CreateItemContent } from "../shopping/createItemContent";
import { ToastContainer } from "react-toastify";
import {
  ItemCategory,
  UpdateShoppingListAction,
  UpdateShoppingListEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { useNotifcation } from "../../lib/hooks/useNotification";
import { EcoLabel } from "../elements/ecoLabel";
import { SelectPlaceButton } from "../shopping/SelectPlaceButton";

export function ShoppingView() {
  const [itemName, setItemName] = useState("");
  const [showCreateItemModal, setShowCreateItemModal] = useState(false);
  const [showAddItemDropdown, setShowAddItemDropdown] = useState(false);
  const [amount, setAmount] = useState(1);
  const [orderedCategories, setOrderedCategories] = useState<
    ItemCategoryWithBought[]
  >([]);

  const notify = useNotifcation();

  const latestUpdate = useRef(Date.now());

  const { group, hasFetchedGroup } = useContext(GroupContext);
  const {
    shoppingList,
    setShoppingList,
    items,
    itemsMap,
    isFetchingItems,
    selectedCategoryOrderId,
    categoryOrders,
  } = useContext(DataContext);

  const selectedCategoryOrder = useMemo(
    () => categoryOrders.find((co) => co._id === selectedCategoryOrderId),
    [selectedCategoryOrderId, categoryOrders]
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let categories =
      selectedCategoryOrder?.order ?? Object.values(ItemCategory);

    // if new categories have been added by admin we add the to the list here
    categories = categories.concat(
      ...(Object.values(ItemCategory).filter(
        (k) => !categories.includes(k as ItemCategory)
      ) as ItemCategory[])
    );

    setOrderedCategories(categories);
  }, [selectedCategoryOrder]);

  function bulkMoveItems(
    fromCategory: ItemCategoryWithBought,
    action: UpdateShoppingListAction
  ) {
    if (fromCategory === "Købte") {
      updateShoppingList(shoppingList?.boughtItems || [], action);
    } else {
      updateShoppingList(
        (shoppingList?.items || []).filter((it) =>
          fromCategory === ItemCategory.Other
            ? !!it.isOther
            : !it.isOther && itemsMap[it._id].category === fromCategory
        ),
        action
      );
    }
  }

  async function updateShoppingList(
    items: ShoppingListItemIdAndAmount[],
    action: UpdateShoppingListAction,
    isOther?: number
  ) {
    if (!shoppingList) {
      notify("An error occurred");
      return;
    }

    items = isOther ? items.map((i) => ({ ...i, isOther })) : items;

    let newItems = shoppingList.items;
    let newBoughtItems = shoppingList.boughtItems;

    if (action === UpdateShoppingListAction.Add) {
      items.forEach((item) => {
        const existingItem = newItems.find((i) => i._id === item._id);
        if (existingItem) {
          existingItem.amount += item.amount;
        } else {
          newItems.unshift(item);
        }
      });
      newBoughtItems = newBoughtItems.filter(
        (item) => !items.find((itemToMove) => itemToMove._id === item._id)
      );
    } else if (action === UpdateShoppingListAction.Buy) {
      newItems = newItems.filter(
        (item) => !items.find((itemToMove) => itemToMove._id === item._id)
      );
      newBoughtItems.unshift(...items);
    } else if (action === UpdateShoppingListAction.ClearBought) {
      newBoughtItems = [];
    }

    // Remove duplicates
    newItems = Array.from(new Set(newItems.map((i) => i._id))).map(
      (id) => newItems.find((i) => i._id === id)!
    );
    newBoughtItems = Array.from(new Set(newBoughtItems.map((i) => i._id))).map(
      (id) => newBoughtItems.find((i) => i._id === id)!
    );

    setShoppingList({
      ...shoppingList,
      items: newItems,
      boughtItems: newBoughtItems,
    });
    const updatedAt = Date.now();
    latestUpdate.current = updatedAt;
    const [err, data] = await callEndpoint(UpdateShoppingListEndpoint, {
      items,
      action,
    });

    if (err) {
      notify("Opdatering af indkøbsliste: Der skete en fejl");
      return;
    }

    if (latestUpdate.current === updatedAt) {
      setShoppingList(data);
    }
  }

  const filteredItems: ItemWithPopularity[] = useMemo(
    () =>
      items.filter((item) =>
        doNormalize((item.isOrganic ? "økologisk " : "") + item.name).includes(
          doNormalize(itemName)
        )
      ),
    [itemName, items]
  );

  function confirmItemName() {
    if (!itemName) {
      return;
    }

    const existingItem = filteredItems.find(
      (item) => doNormalize(item.name) === doNormalize(itemName)
    );
    if (existingItem) {
      addItem(existingItem._id);
    } else if (
      shoppingList?.items.find(
        (item) =>
          doNormalize(itemsMap[item._id]!.name) === doNormalize(itemName)
      )
    ) {
      notify(`${itemName} er allerede på din indkøbsliste`);
    } else {
      setShowCreateItemModal(true);
    }
  }

  function addItem(itemId: string, isOther?: number) {
    updateShoppingList(
      [{ _id: itemId, amount: amount }],
      UpdateShoppingListAction.Add,
      isOther
    );

    if (!isOther) {
      addItemToStats(itemId);
    }
    setItemName("");
    setAmount(1);
  }

  const itemIsOnList = useMemo(
    () =>
      !!shoppingList?.items.find(
        (item) =>
          doNormalize(
            item.isOther ? item._id : itemsMap[item._id]?.name || ""
          ) === doNormalize(itemName)
      ),
    [shoppingList, itemName, itemsMap]
  );

  const itemsInCategory: { [k in string]: ShoppingListItemIdAndAmount[] } =
    useMemo(() => {
      if (!shoppingList) {
        return {};
      }

      const result = {} as Record<
        ItemCategoryWithBought,
        ShoppingListItemIdAndAmount[]
      >;

      Object.values(ItemCategory).forEach(
        (category) => (result[category] = [])
      );

      shoppingList.items.forEach((i) =>
        result[
          i.isOther
            ? ItemCategory.Other
            : itemsMap[i._id]?.category || ItemCategory.Other
        ].push(i)
      );

      return result;
    }, [shoppingList, itemsMap]);

  useEffect(() => {
    if (showAddItemDropdown) {
      inputRef.current?.focus();
    }
  }, [showAddItemDropdown]);

  const sortedItemOptions = useMemo(
    () =>
      filteredItems
        .sort((a, b) => {
          if (doNormalize(itemName) === doNormalize(a.name)) {
            return -1;
          } else if (doNormalize(itemName) === doNormalize(b.name)) {
            return 1;
          }

          if (a.popularity === b.popularity) {
            return a.name.localeCompare(b.name);
          }
          return (b.popularity || 0) - (a.popularity || 0);
        })
        .slice(0, 30),
    [filteredItems, itemName]
  );

  const suggestions = useMemo(() => {
    const itemsOnList = shoppingList?.items.map((i) => i._id) || [];
    return sortedItemOptions
      .filter((i) => !itemsOnList.includes(i._id))
      .slice(0, 12);
  }, [sortedItemOptions, shoppingList]);

  return (
    <View style={{ position: "relative" }}>
      <ToastContainer style={{ marginBottom: 90 }} />
      {hasFetchedGroup && !group ? (
        <ViewMessage
          message="Du er ikke en del af nogen gruppe endnu."
          otherMessage="Gå i indstillinger for at lave eller deltage i en gruppe..."
          loading={!hasFetchedGroup}
        />
      ) : (
        <>
          {showAddItemDropdown && (
            <div
              className="mask"
              style={{ marginTop: "62px", zIndex: 98 }}
              onClick={() => setShowAddItemDropdown(false)}
            />
          )}
          <div
            style={{
              background: "white",
              padding: "12px 20px",
              width: "calc(100% - 40px)",
              boxShadow:
                "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
              display: "flex",
              alignItems: "center",
              position: "relative",
              zIndex: 100,
            }}
          >
            <SearchOutlined style={{ marginRight: 8, fontSize: 20 }} />
            <MInput
              size="large"
              placeholder="Søg varer..."
              innerref={inputRef}
              onFocus={() => setShowAddItemDropdown(true)}
              allowClear
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              onPressEnter={confirmItemName}
              suffix={
                showAddItemDropdown && (
                  <PlusOutlined
                    style={{
                      color: BLUE,
                      fontSize: 24,
                      opacity: itemName ? 1 : 0.3,
                    }}
                    onClick={confirmItemName}
                  />
                )
              }
              className="new-item-input"
            />
            {!showAddItemDropdown && categoryOrders.length > 1 && (
              <SelectPlaceButton style={{ marginLeft: 8 }} />
            )}
            {showAddItemDropdown && (
              <div
                style={{
                  fontSize: 16,
                  color: BLUE,
                  fontWeight: "bold",
                  marginLeft: 20,
                }}
                onClick={() => setShowAddItemDropdown(false)}
              >
                LUK
              </div>
            )}
          </div>
          {showAddItemDropdown && (
            <div
              style={{
                width: "90%",
                margin: "0 5%",
                background: "white",
                boxShadow:
                  "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
                position: "absolute",
                top: "62px",
                height: SEARCH_ITEM_LIST_HEIGHT,
                overflow: "hidden",
                zIndex: 99,
              }}
            >
              {isFetchingItems ? (
                <Spinner />
              ) : (
                showAddItemDropdown && (
                  <AddItemContent
                    itemsOnList={shoppingList?.items || []}
                    sortedItemOptions={sortedItemOptions}
                    currentItemName={itemName}
                    itemIsOnList={itemIsOnList}
                    onNewItem={() => setShowCreateItemModal(true)}
                    addItemToList={(item) => {
                      inputRef.current?.focus();
                      addItem(item._id);
                    }}
                  />
                )
              )}
            </div>
          )}
          <div
            style={{
              padding: 8,
              display: "flex",
              gap: 4,
              flexWrap: "wrap",
              justifyContent: "center",
              userSelect: "none",
              height: 64,
              overflow: "hidden",
            }}
          >
            {suggestions.map((i) => (
              <div
                key={i._id}
                onClick={() => addItem(i._id)}
                style={{
                  padding: "4px 15px",
                  border: `1px dashed #d9d9d9`,
                  borderRadius: 4,
                  fontSize: 14,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 24,
                  background: "white",
                  columnGap: 4,
                }}
              >
                <div>{i.name}</div>
                {i.isOrganic && (
                  <EcoLabel size={1} style={{ marginBottom: -1 }} />
                )}
              </div>
            ))}
          </div>
          {!shoppingList ||
          (shoppingList.items.length === 0 &&
            shoppingList.boughtItems.length === 0) ? (
            <ViewMessage
              message={
                <div>
                  Din indkøbsliste er tom{" "}
                  <SmileOutlined style={{ marginLeft: "8px" }} />
                </div>
              }
            />
          ) : (
            <div
              style={{
                height: "calc(100% - 80px)",
                width: "100%",
                overflow: "auto",
              }}
            >
              {orderedCategories
                .filter((category) => itemsInCategory[category].length > 0)
                .map((category) => (
                  <CategorySection
                    key={category}
                    category={category}
                    items={itemsInCategory[category]}
                    onItemClick={(item) =>
                      updateShoppingList([item], UpdateShoppingListAction.Buy)
                    }
                    onAmountClick={(item, amount) =>
                      updateShoppingList(
                        [{ ...item, amount: amount ?? 1 }],
                        UpdateShoppingListAction.Add
                      )
                    }
                    onClear={() =>
                      bulkMoveItems(category, UpdateShoppingListAction.Buy)
                    }
                  />
                ))}
              {shoppingList.boughtItems.length > 0 ? (
                <CategorySection
                  category="Købte"
                  items={shoppingList.boughtItems}
                  onItemClick={(item) =>
                    updateShoppingList(
                      [item],
                      UpdateShoppingListAction.Add,
                      item.isOther
                    )
                  }
                  onAmountClick={() => {}}
                  onClear={() =>
                    bulkMoveItems("Købte", UpdateShoppingListAction.ClearBought)
                  }
                />
              ) : null}
              <div style={{ height: "192px" }} />
            </div>
          )}
        </>
      )}
      <MModal
        visible={showCreateItemModal}
        onClose={() => {
          setShowCreateItemModal(false);
          inputRef.current?.focus();
        }}
        title="Opret ny vare"
        content={
          <CreateItemContent
            onClose={() => {
              setShowCreateItemModal(false);
              inputRef.current?.focus();
            }}
            onFinish={(item, isOther) => {
              inputRef.current?.focus();
              addItem(item, isOther);
              setShowCreateItemModal(false);
            }}
            initialItemName={itemName}
          />
        }
      />
    </View>
  );
}
