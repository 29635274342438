import { ShoppingCartOutlined } from "@ant-design/icons";
import { Button, Checkbox } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../lib/contexts";
import { categoryToIcon } from "../../lib/definitions";
import { ShoppingListItemIdAndAmount } from "../../lib/models";
import { MModal } from "../elements/mmodal";
import { callEndpoint } from "../../lib/functions";
import {
  UpdateShoppingListAction,
  UpdateShoppingListEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { useNotifcation } from "../../lib/hooks/useNotification";

interface AddToShoppingListPopupProps {
  visible: boolean;
  onClose(): void;
  dishIds: string[];
}

export function AddToShoppingListPopup(props: AddToShoppingListPopupProps) {
  const [allIngredients, setAllIngredients] = useState<
    ShoppingListItemIdAndAmount[]
  >([]);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [isTransferring, setIsTransferring] = useState(false);

  const { dishes, setShoppingList, itemsMap } = useContext(DataContext);
  const notify = useNotifcation();

  useEffect(() => {
    const ingredients: { [name in string]: ShoppingListItemIdAndAmount } = {};

    props.dishIds.forEach((dishId) =>
      (dishes.find((dish) => dish._id === dishId)?.ingredients || []).forEach(
        (ing) => {
          if (!ingredients[ing.id]) {
            ingredients[ing.id] = { _id: ing.id, amount: ing.amount };
          } else {
            ingredients[ing.id].amount += ing.amount;
          }
        }
      )
    );

    setAllIngredients(Object.values(ingredients));
    setSelectedItemIds(Object.keys(ingredients));
  }, [props.dishIds, dishes]);

  function onItemClick(item: ShoppingListItemIdAndAmount) {
    if (selectedItemIds.includes(item._id)) {
      setSelectedItemIds((is) => is.filter((id) => id !== item._id));
    } else {
      setSelectedItemIds((is) => is.concat(item._id));
    }
  }

  return (
    <MModal
      visible={props.visible}
      onClose={props.onClose}
      title="Overfør til indkøbsliste"
      content={
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "24px",
            }}
          >
            <ShoppingCartOutlined style={{ fontSize: "120px" }} />
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "min-content min-content auto min-content",
              columnGap: "6px",
              rowGap: "4px",
              alignItems: "center",
            }}
          >
            {allIngredients.map((i) => {
              const item = itemsMap[i._id];
              return (
                <React.Fragment key={i._id}>
                  <div className="zoom" style={{ marginRight: "8px" }}>
                    <Checkbox
                      checked={selectedItemIds.includes(i._id)}
                      onClick={() => onItemClick(i)}
                    />
                  </div>
                  <div style={{ whiteSpace: "nowrap", marginTop: -3 }}>
                    {i.amount > 1 ? `${i.amount} x` : ""}
                  </div>
                  <div
                    onClick={() => onItemClick(i)}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: "8px",
                      marginTop: -3,
                    }}
                  >
                    {item.name}
                  </div>
                  <div style={{ fontSize: 20 }}>
                    {categoryToIcon[item.category]}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      }
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            style={{ marginRight: "8px" }}
            onClick={props.onClose}
          >
            Luk
          </Button>
          <Button
            size="large"
            type="primary"
            onClick={async () => {
              setIsTransferring(true);
              const [err, data] = await callEndpoint(
                UpdateShoppingListEndpoint,
                {
                  items: allIngredients.filter((ing) =>
                    selectedItemIds.includes(ing._id)
                  ),
                  action: UpdateShoppingListAction.Add,
                }
              );
              setIsTransferring(false);

              if (err) {
                notify("Opdatering af indkøbliste: Der skete en fejl");
                return;
              }

              props.onClose();
              setShoppingList(data);
            }}
            disabled={selectedItemIds.length === 0}
            loading={isTransferring}
          >
            Overfør
          </Button>
        </div>
      }
    />
  );
}
