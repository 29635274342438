import { CSSProperties, useContext, useMemo, useState } from "react";
import { DataContext } from "../../lib/contexts";
import { Menu, MenuItem } from "@mui/material";
import { Button } from "antd";

interface SelectPlaceButtonProps {
  style?: CSSProperties;
}

export function SelectPlaceButton(props: SelectPlaceButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    categoryOrders,
    selectedCategoryOrderId,
    updateSelectedCategoryOrderId,
  } = useContext(DataContext);

  const selectedCategoryOrderName = useMemo(
    () =>
      categoryOrders.find((co) => co._id === selectedCategoryOrderId)?.name ||
      "",
    [selectedCategoryOrderId, categoryOrders]
  );

  return (
    <div style={props.style}>
      <Button
        type="primary"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={{ padding: "4px 10px" }}
        onClick={handleClick}
      >
        {selectedCategoryOrderName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {categoryOrders.map((co) => (
          <MenuItem
            key={co._id}
            selected={co._id === selectedCategoryOrderId}
            className="place-selector"
            onClick={() => {
              updateSelectedCategoryOrderId(co._id);
              handleClose();
            }}
          >
            {co.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
