import {
  CategoryOrder,
  DeleteCategoryOrderEndpoint,
  ItemCategory,
  UpdateCategoryOrderEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/madplanen";
import { Button, Input } from "antd";
import { useContext, useRef, useState } from "react";
import { callEndpoint } from "../../lib/functions";
import { useNotifcation } from "../../lib/hooks/useNotification";
import { GRAY_BACKGROUND, VERY_LIGHT_GRAY } from "../../lib/style_definitions";
import { CategoryItem } from "./categoryItem";
import { FaAngleRight, FaAngleDown } from "react-icons/fa6";
import { DataContext } from "../../lib/contexts";
import { OptimisticalCategoryOrderUpdateAction } from "../../lib/models";
import { DeleteOutlined } from "@ant-design/icons";

interface CategoryOrderProps {
  categoryOrder: CategoryOrder;
  optimisticallyUpdateCategoryOrders(
    action: OptimisticalCategoryOrderUpdateAction
  ): void;
}

export function CategoryOrderSection(props: CategoryOrderProps) {
  const [opened, setOpened] = useState(false);
  const [name, setName] = useState(props.categoryOrder.name);

  const timeoutId = useRef<NodeJS.Timeout>();

  const {
    refreshCategoryOrders,
    selectedCategoryOrderId,
    updateSelectedCategoryOrderId,
    categoryOrders,
  } = useContext(DataContext);
  const notify = useNotifcation();

  async function updateName() {
    props.optimisticallyUpdateCategoryOrders({
      type: "name",
      id: props.categoryOrder._id,
      name: name.trim(),
    });

    const [err] = await callEndpoint(UpdateCategoryOrderEndpoint, {
      id: props.categoryOrder._id,
      update: { type: "name", name: name.trim() },
    });

    refreshCategoryOrders();

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
    }
  }

  async function changeOrder(category: ItemCategory, direction: "up" | "down") {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    props.optimisticallyUpdateCategoryOrders({
      type: "order",
      id: props.categoryOrder._id,
      category,
      direction,
    });

    const [err] = await callEndpoint(UpdateCategoryOrderEndpoint, {
      id: props.categoryOrder._id,
      update: { type: "order", category, direction },
    });

    timeoutId.current = setTimeout(refreshCategoryOrders, 2000);

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
    }
  }

  async function deleteCategoryOrder() {
    if (
      !window.confirm(
        `Er du sikker på at du vil slette kategorirækkefølgen '${props.categoryOrder.name}'`
      )
    ) {
      return;
    }

    props.optimisticallyUpdateCategoryOrders({
      type: "delete",
      id: props.categoryOrder._id,
    });

    const [err] = await callEndpoint(DeleteCategoryOrderEndpoint, {
      id: props.categoryOrder._id,
    });

    refreshCategoryOrders();

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
      return;
    }

    if (selectedCategoryOrderId === props.categoryOrder._id) {
      updateSelectedCategoryOrderId(
        categoryOrders.find((co) => co._id !== props.categoryOrder._id)?._id ||
          ""
      );
    }
  }

  return (
    <div
      style={{
        padding: "8px 12px 8px 16px",
        background: GRAY_BACKGROUND,
        borderRadius: 4,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 16,
        }}
        onClick={() => setOpened((curr) => !curr)}
      >
        <div>{props.categoryOrder.name}</div>
        {opened ? <FaAngleDown /> : <FaAngleRight />}
      </div>
      {opened && (
        <div style={{ marginTop: 16 }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              columnGap: 12,
            }}
          >
            <Input
              size="large"
              onBlur={updateName}
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={{ marginBottom: 8 }}
            />
            <Button
              icon={<DeleteOutlined />}
              type="primary"
              size="large"
              danger
              onClick={deleteCategoryOrder}
            />
          </div>
          <div
            style={{
              height: "280px",
              overflow: "auto",
              padding: "8px 16px",
              border: "1px solid " + VERY_LIGHT_GRAY,
              borderRadius: "4px",
              background: "white",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "min-content auto min-content min-content",
                columnGap: "8px",
                rowGap: "8px",
                alignItems: "center",
              }}
            >
              {props.categoryOrder.order.map((category, i) => (
                <CategoryItem
                  key={category}
                  category={category}
                  onChange={(wasUp) =>
                    changeOrder(category, wasUp ? "up" : "down")
                  }
                  upDisabled={i === 0}
                  downDisabled={i === props.categoryOrder.order.length - 1}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
