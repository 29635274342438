import { useContext, useState } from "react";
import { View } from "./view";
import { GroupContent } from "../settings/groupContent";
import { AdminContent } from "../settings/adminContent";
import { CategoryOrdersContent } from "../settings/CategoryOrdersContent";
import { Segment } from "../elements/segment";
import { UserInfoContext } from "../../lib/contexts";
import { StatisticsContent } from "../settings/statisticsContent";
import { LogViewer } from "./LogViewer";
import { UserPanel } from "../settings/UserPanel";

interface SettingsViewProps {
  onLogout(): void;
}

export function SettingsView(props: SettingsViewProps) {
  const [showLogs, setShowLogs] = useState(false);

  const { email } = useContext(UserInfoContext);

  return (
    <View scrollable header="Indstillinger">
      {showLogs && <LogViewer onClose={() => setShowLogs(false)} />}
      <Segment title="Gruppe">
        <GroupContent />
      </Segment>
      <Segment title="Kategorirækkefølger">
        <CategoryOrdersContent />
      </Segment>
      <Segment title="Statistik">
        <StatisticsContent />
      </Segment>
      {email === "thisbecasper@gmail.com" && (
        <Segment title="Administration">
          <AdminContent />
        </Segment>
      )}
      <UserPanel
        style={{ margin: "64px 20px 0 20px" }}
        onLogsClick={() => setShowLogs(true)}
        onLogout={props.onLogout}
      />
      <div style={{ height: 200 }} />
    </View>
  );
}
