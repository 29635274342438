import { CSSProperties } from "react";
import { version } from "../../../package.json";
import { TbVersionsFilled } from "react-icons/tb";
import { IoLogOut } from "react-icons/io5";
import { TbLogs } from "react-icons/tb";

interface UserPanelProps {
  onLogsClick(): void;
  onLogout(): void;
  style?: CSSProperties;
}

export function UserPanel(props: UserPanelProps) {
  return (
    <div
      style={{
        display: "grid",
        borderRadius: 8,
        border: "1px solid rgba(0,0,0,0.1)",
        background: "white",
        gridAutoRows: "1fr",
        ...props.style,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          padding: "12px 24px",
          color: "rgba(0,0,0,0.8)",
        }}
        onClick={() => window.location.reload()}
      >
        <div>Version: {version}</div>
        <TbVersionsFilled style={{ fontSize: 24 }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          padding: "12px 24px",
          color: "rgba(0,0,0,0.8)",
        }}
        onClick={props.onLogsClick}
      >
        <div>Logs</div>
        <TbLogs style={{ fontSize: 24 }} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 24px",
          color: "rgba(0,0,0,0.8)",
        }}
        onClick={props.onLogout}
      >
        <div>Log ud</div>
        <IoLogOut style={{ fontSize: 24 }} />
      </div>
    </div>
  );
}
